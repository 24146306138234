<template>
  <div v-cloak>
    <div v-if="apiLoaded || errorText">
      <div v-if="apiLoaded" class="wrap">
        <a @click="$router.go(-1)" class="back-link">
          <v-icon class="icon-item icon-chevron-left"></v-icon>
          Назад
        </a>
        <h1>Настройка подсистемы «Конструктор комплексной модернизации»</h1>

        <table class="config-list-table">
          <thead>
            <tr>
              <th>ID конфигурации</th>
              <th>Наименование</th>
              <th>Дата создания</th>
              <th>Дата изменения</th>
              <th>Статус</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="config in configs" :key="config.id">
              <td>{{ config.id }}</td>
              <td>{{ config.content.title }}</td>
              <td>{{ config.createdDate | dateFormat }}</td>
              <td v-if="config.lastModifiedDate">{{ config.lastModifiedDate | dateFormat }}</td>
              <td v-else>–</td>
              <td v-if="config.active">
                <div style="color: darkgreen;">Активный</div>
                <a href="#" @click.stop="setActive(config.id, false)">Сделать неактивным</a>
              </td>
              <td v-else>
                <div>Неактивный</div>
                <a href="#" @click.stop="setActive(config.id, true)">Сделать активным</a>
              </td>
              <td style="width: 10px">
                <div class="edit" title="Просмотр данных комплексной модернизации по данной конфигурации">
                  <router-link :to="'/standard/vnii/' + config.id">
                    <v-icon class="icon-item icon-briefcase-eye-outline"></v-icon>
                  </router-link>
                </div>
              </td>
              <td style="width: 10px">
                <div class="edit" title="Редактирование конфигурации">
                  <router-link :to="'/config/standardEdit/' + config.id">
                    <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="save-buttons">
          <v-btn color="primary" @click="$router.push('/config/standardEdit/')">Создать конфигурацию</v-btn>
        </div>

      </div>
      <div v-if="errorText != null" class="error" style="position: fixed;bottom: 0">
        Ошибка: {{ errorText }}
      </div>
      <div v-else style="height:1000px"></div>
      <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
    </div>
  </div>
</template>

<script>
import LoaderDialog from "@/components/elements/LoaderDialog.vue";
import {getDomainConfigs, setDomainConfigStatus} from "@/modules/api.configs";

export default {
  name: "standardMonitoring",
  props: {},
  components: {LoaderDialog},
  data() {
    return {
      configs: [],
      domain: 'standard_report',
      loading: false,
      apiLoaded: false,
      errorText: null,
    };
  },

  methods: {
    async loadConfigs() {
      this.loading = true
      let req = await getDomainConfigs(this.domain)
      if (req.ok) {
        this.configs = req.payload
        this.sortConfigsByCreatedDate()
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.loading = false
    },
    async setActive(configId, active) {
      this.loading = true
      let req = await setDomainConfigStatus(this.domain, configId, active)
      if (req.ok) {
        await this.loadConfigs()
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.loading = false
    },
    sortConfigsByCreatedDate() {
      this.configs.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1)
    },
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    await this.loadConfigs()
  }
};
</script>

<style lang="scss">
@import "../../../styles/main.scss";
</style>

<style scoped lang="scss">
.config-list-table {
  border-collapse: collapse;
  a {
    font-size: 14px;
  }
}
</style>